import React, { Component } from "react"
import css from "./stylesheets/ProductInfo.module.scss"
import ImageForSize from "../utils/imageForSize"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import UtilMath from "../utils/UtilMath"

export default class ProductInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isShippingInfoOpen: false,
    }
    this._onAddVariantToCart = this._onAddVariantToCart.bind(this)
    this._onChangeVariant = this._onChangeVariant.bind(this)
    this.toggleShipping = this.toggleShipping.bind(this)
  }

  _onAddVariantToCart() {
    this.props.onAddVariantToCart()
  }

  _onChangeVariant(e) {
    this.props.onVariantChanged(e)
  }

  toggleShipping() {
    const isShippingOpen = !this.state.isShippingInfoOpen
    this.setState({
      isShippingInfoOpen: isShippingOpen,
    })
  }

  render() {
    const theVariant = this.props.variants[this.props.currentVatiant]
    const theVariantImageSrc = ImageForSize.getImage(theVariant.image, {
      maxWidth: 128,
      maxHeight: 128,
      crop: "center",
    })
    const classShippingInfoState = this.state.isShippingInfoOpen
      ? css.shippingInfoIsOpen
      : css.shippingInfoIsClose

    return (
      <div className={css.base}>
        {/* title */}
        {this.props.variants.length > 1 ? (
          <h2 className={css.title}>
            {this.props.title} {theVariant.title}
          </h2>
        ) : (
          <h2 className={css.title}>{this.props.title}</h2>
        )}

        {/* price */}
        <div>
          <p className={css.price}>{`${UtilMath.delimiter(theVariant.price)} ${
            theVariant.priceV2.currencyCode
          } + TAX`}</p>
        </div>

        {/* variant */}
        {this.props.variants.length > 1 && (
          <div style={{ marginBottom: `1vw` }}>
            <div className={css.variantsWrap}>
              <select
                name="variants"
                className={css.variants}
                onChange={this._onChangeVariant}
                onBlur={this._onChangeVariant}
                style={{
                  backgroundImage: `url(${theVariantImageSrc})`,
                }}
              >
                {this.props.variants.map(v => {
                  return (
                    <option value={v.id} key={v.id}>
                      {v.title}
                    </option>
                  )
                })}
              </select>
            </div>
          </div>
        )}
        <div
          className={css.doc}
          dangerouslySetInnerHTML={{ __html: this.props.children }}
        />

        <div style={{ marginBottom: `1vw` }}>
          {theVariant.available ? (
            <button
              className={css.buyButton}
              onClick={this._onAddVariantToCart}
            >
              Add to cart
            </button>
          ) : (
            <button className={css.buyButton} disabled={true}>
              Sold out
            </button>
          )}
        </div>

        <div className={css.meta}>
          {this.props.shippingHTML && (
            <div>
              <button className={css.shipping} onClick={this.toggleShipping}>
                送料について
              </button>
            </div>
          )}

          <div>
            <OutboundLink
              href={`mailto:contact@skwat.site?subject=【${this.props.title}についてのお問い合わせ】`}
              target={`_blank`}
            >
              お問い合わせ
            </OutboundLink>
          </div>
        </div>

        <div className={css.metaLink}>
          <ul>
            <li>
              <OutboundLink
                href={`https://cart.skwat.site/policies/legal-notice`}
                target={`_blank`}
              >
                特定商取引法に基づく表記
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href={`https://cart.skwat.site/policies/privacy-policy/`}
                target={`_blank`}
              >
                Privacy Policy
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href={`https://cart.skwat.site/policies/terms-of-service`}
                target={`_blank`}
              >
                Terms of Use
              </OutboundLink>
            </li>
          </ul>
        </div>

        <div className={classShippingInfoState + ` ` + css.shoppingInfo}>
          <button className={css.shoppingInfoCloseButton} onClick={this.toggleShipping}>×</button>
          <div dangerouslySetInnerHTML={{ __html: this.props.shippingHTML }} />
        </div>
      </div>
    )
  }
}
