export default class ImageForSize {
  static getImage(image, _ref) {
    var maxWidth = _ref.maxWidth,
      maxHeight = _ref.maxHeight

    var splitUrl = image.src.split("?")
    var notQuery = splitUrl[0]
    var query = splitUrl[1] ? "?" + splitUrl[1] : ""

    // Use the section before the query
    var imageTokens = notQuery.split(".")

    // Take the token before the file extension and append the dimensions
    var imagePathIndex = imageTokens.length - 2

    imageTokens[imagePathIndex] =
      imageTokens[imagePathIndex] + "_" + maxWidth + "x" + maxHeight

    if (_ref.crop) {
      imageTokens[imagePathIndex] += "_crop_" + _ref.crop
    }

    return "" + imageTokens.join(".") + query
  }
}
