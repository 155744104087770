export default class Base64 {
  static encode(str) {
    if (typeof window !== `undefined`) {
      return window[`btoa`](unescape(encodeURIComponent(str)))
    }
    return "error"
  }
  static decode(str) {
    if (typeof window !== `undefined`) {
      return decodeURIComponent(escape(window[`atob`](str)))
    }
    return "error"
  }
}
