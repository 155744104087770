import React, { Component } from "react"
import "./stylesheets/LineItem.scss"
import ImageForSize from "../utils/imageForSize"
import UtilMath from "../utils/UtilMath"
class LineItem extends Component {
  constructor(props) {
    super(props)
    this.decrementQuantity = this.decrementQuantity.bind(this)
    this.incrementQuantity = this.incrementQuantity.bind(this)
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity)
  }

  /**
   *
   * @returns {*}
   */
  render() {
    // 小さいサイズの画像を生成
    const src = ImageForSize.getImage(this.props.line_item.variant.image, {
      maxWidth: 100,
      maxHeight: 100,
    })

    return (
      <li className="Line-item">
        <div className="Line-item__img">
          {this.props.line_item.variant.image ? (
            <img src={src} alt={`${this.props.line_item.title} product shot`} />
          ) : null}
        </div>
        <div className="Line-item__content">
          <button
            className="Line-item__remove"
            onClick={() =>
              this.props.removeLineItemInCart(this.props.line_item.id)
            }
          >
            ×
          </button>

          <div className="Line-item__content-row">
            <div className="Line-item__title">
              {this.props.line_item.title} ({this.props.line_item.variant.title}
              )
            </div>
          </div>
          <div className="Line-item__content-row">
            <div className="Line-item__price-box">
              <div className="Line-item__price">
                {UtilMath.delimiter(
                  (
                    this.props.line_item.quantity *
                    this.props.line_item.variant.price
                  ).toFixed(0)
                )}{" "}
                JPY
              </div>

              <div className="Line-item__quantity-container">
                <button
                  className="Line-item__quantity-update"
                  onClick={() =>
                    this.decrementQuantity(this.props.line_item.id)
                  }
                >
                  -
                </button>
                <span className="Line-item__quantity">
                  {this.props.line_item.quantity}
                </span>
                <button
                  className="Line-item__quantity-update"
                  onClick={() =>
                    this.incrementQuantity(this.props.line_item.id)
                  }
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>
      </li>
    )
  }
}

export default LineItem
