import React, { Component } from "react"
import Cart from "./Cart"
import ShopifySingletonClient from "./ShopifySingletonClient"

export default class CommonCartLayout extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super()
    // チェックアウト用のアイテムを保持するオブジェクト作成
    this.state = {
      isCartOpen: props.isCartOpen,
      product: null,
      checkout: null,
    }

    // utility
    this._isMounted = false

    // bind
    this.doCartClose = this.doCartClose.bind(this)
    this.removeItemFromCart = this.removeItemFromCart.bind(this)
    this.updateQuantityInCart = this.updateQuantityInCart.bind(this)
  }

  /**
   *
   */
  componentDidMount() {
    this._isMounted = true
  }

  /**
   *
   */
  componentWillUnmount() {
    this._isMounted = false
  }

  /**
   * 商品がカートに入った
   *
   * @param variantId
   * @param quantity
   */
  addItemToCart(variantId, quantity) {
    this.setState({
      isCartOpen: true,
    })

    const lineItems = [{ variantId: variantId, quantity: quantity }]
    const checkout = ShopifySingletonClient.getCheckout()
    const client = ShopifySingletonClient.getClient()

    // カートに追加
    client.checkout.addLineItems(checkout.id, lineItems).then(res => {
      ShopifySingletonClient.setCheckout(res)

      // state を更新
      this.setState({
        checkout: res,
      })
    })
  }

  /**
   *
   * @param lineItemId
   * @returns {Q.Promise<any> | void | * | PromiseLike<any>}
   */
  removeItemFromCart(lineItemId) {
    // console.log(`remove from cart`)
    const checkoutId = ShopifySingletonClient.getCheckout().id
    const client = ShopifySingletonClient.getClient()
    return client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then(res => {
        // render したいから無理やりリロード
        this.setState({
          checkout: ShopifySingletonClient.setCheckout(res),
        })
      })
  }

  /**
   *
   * @param lineItemId
   * @param quantity
   * @returns {Q.Promise<any> | void | * | PromiseLike<any>}
   */
  updateQuantityInCart(lineItemId, quantity) {
    // console.log(`Update Quantity in cart`)
    const client = ShopifySingletonClient.getClient()
    const checkoutId = ShopifySingletonClient.getCheckout().id
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then(res => {
        this.setState({
          checkout: ShopifySingletonClient.setCheckout(res),
        })
      })
  }

  /**
   *
   */
  doCartClose() {
    this.setState({
      isCartOpen: false,
    })
  }

  /**
   *
   */
  doCartOpen() {
    this.setState({
      isCartOpen: true,
    })
  }

  /**
   *
   * @returns {*}
   */
  render() {
    return (
      <div>
        {this.props.children}

        <Cart
          checkout={ShopifySingletonClient.getCheckout()}
          isCartOpen={this.state.isCartOpen}
          handleCartClose={this.doCartClose}
          updateQuantityInCart={this.updateQuantityInCart}
          removeLineItemInCart={this.removeItemFromCart}
        />
      </div>
    )
  }
}
