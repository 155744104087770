import React, { Component } from "react"
import css from "./stylesheets/CommonHeader.module.scss"
import { Link } from "gatsby"
import GlobalMenu from "./menu/GlobalMenu"

class CommonHeader extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  doToggleMenu = () => {
    this.setState(state => ({
      isMenuOpen: !state.isMenuOpen,
    }))
    console.log(`isMenuOpen は ${this.state.isMenuOpen}`)
  }


  render() {
    let colorMode = this.props.colorMode === "white" ? "is-white-text" : ""
    let titleHtml = this.props.title ? this.props.title : "SKWAT"



    return (
      <div>
        {/*<div className={css.base + " " + colorMode}>*/}
        {/*  <h1 className={css.title}>*/}
        {/*    <Link to={`/`}>*/}
        {/*      <span dangerouslySetInnerHTML={{ __html: titleHtml }} />*/}
        {/*    </Link>*/}
        {/*  </h1>*/}
        {/*</div>*/}

        {/*<GlobalMenu leftOpen={Boolean(this.props.showMenu)} colorMode={this.props.colorMode} />*/}


        <header className={css.newHeader}>
          <h1>
            <a href={`/`}>SKWAT</a>
          </h1>

          <nav className={css.menuBase}>
            <div className={css.menuBase__toggle}>
              <button
                className={css.menuToggle}
                title={`Toggle Menu`}
                onClick={this.doToggleMenu}
              >
                {this.state.isMenuOpen ? "Close" : "Menu"}
              </button>
            </div>

            <div
              className={
                css.menuBase__content +
                " " +
                (this.state.isMenuOpen && css.isVisible)
              }
            >
              <ul className={css.menuBody}>
                <li>
                  <a href="/STATEMENT">About</a>
                </li>
                <li>
                  <a href={`mailto:contact@skwat.site`}>Contact</a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/skwat.site/"
                    rel="noopener noreferrer"
                    target={"_blank"}
                  >
                    @skwat.site
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </header>
      </div>
    )
  }
}
export default CommonHeader
