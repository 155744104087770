import React, { Component } from "react"
import { Link } from "gatsby"
import { ModalRoutingContext } from "gatsby-plugin-modal-routing"
import Seo from "../components/Seo"
import CommonHeader from "../components/CommonHeader"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import svgClose from "../components/icons/icon-arrow-close.svg"
import ProductInfo from "../components/shopify/ProductInfo"
import "shopify-buy/index.umd.min"
import Base64 from "../components/utils/Base64"
import ImageForSize from "../components/utils/imageForSize"
import ShopifySingletonClient from "../components/shopify/ShopifySingletonClient"

import css from "./stylesheets/VirtualBuildingItem.module.scss"
import "./stylesheets/SlickCustomize.scss"
import CommonCartLayout from "../components/shopify/CommonCartLayout"
import objectFitImages from "object-fit-images"
require("es6-promise").polyfill()
require("isomorphic-fetch")

export default class VirtualBuildingItem extends Component {
  constructor(props) {
    super(props)

    // チェックアウト用のアイテムを保持するオブジェクト作成
    this.state = {
      variantIndex: 0,
      product: null,
      isCartOpen: false,
      checkout: null,
    }
    // state
    this._isMounted = false

    // callback functions
    this._addItemToCart = this._addItemToCart.bind(this)

    this._doUpdateProductState = this._doUpdateProductState.bind(this)
    this._onVariantUpdate = this._onVariantUpdate.bind(this)

    // ref
    this._cartRef = React.createRef()

    // utility
    this._shopifyProductId = Base64.encode(
      "gid://shopify/Product/" + props.pageContext.itemId
    )
  }

  componentDidMount() {
    this._isMounted = true
    ShopifySingletonClient.getClient()
      .product.fetch(this._shopifyProductId)
      .then(this._doUpdateProductState)

    objectFitImages('.fixedAspectRatio img')
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  _doUpdateProductState(res) {
    // プロダクト情報を Shopify から取得し保持
    this._isMounted &&
      this.setState({
        checkout: ShopifySingletonClient.getCheckout(),
        product: res,
      })
  }

  /**
   *
   * @private
   */
  _addItemToCart() {
    this._cartRef.current.doCartOpen()
    const theVariant = this.state.product.variants[this.state.variantIndex]
    this._cartRef.current.addItemToCart(theVariant.id, 1)
  }

  _onVariantUpdate(e) {
    const newIndex = e.currentTarget.selectedIndex
    this.setState({
      variantIndex: newIndex,
    })
  }

  render() {
    // プロダクト情報が取れていなければリターン
    if (!this.state.product) {
      return (
        <ModalRoutingContext.Consumer>
          {({ modal, closeTo }) => {
            // const slickBoxIsModal = modal ? css.wrapIsModal : ""
            return (
              <CommonCartLayout isCartOpen={false} ref={this._cartRef}>
                <div className={css.base}>
                  <div className={css.loading}>
                    <p>Loading...</p>
                  </div>
                </div>
              </CommonCartLayout>
            )
          }}
        </ModalRoutingContext.Consumer>
      )
    }

    const theProduct = this.state.product

    let images = []
    for (let i = 0; i < theProduct.images.length; i += 1) {
      const image = theProduct.images[i]
      const alt = image.altText ? image.altText : ""
      const src = ImageForSize.getImage(image, {
        maxWidth: 1200,
        maxHeight: 800,
      })

      images.push(
        <div className={`fixedAspectRatio is-6x4 ` + css.slickItem} key={i}>
          <div className={css.fixedRatioImage}>
            <img src={src} alt={alt} />
          </div>
        </div>
      )
    }

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    }

    return (
      <ModalRoutingContext.Consumer>
        {({ modal, closeTo }) => {
          const slickBoxIsModal = modal ? css.wrapIsModal : ""
          return (
            <CommonCartLayout isCartOpen={false} ref={this._cartRef}>
              <div className={css.base}>
                <Seo lang={`en`} title={theProduct.title} />

                {/* header */}
                <div className={css.header}>
                  {modal ? (
                    <div className={css.closeBox}>
                      <Link to={closeTo}>
                        <img src={svgClose} alt="close the modal window" />
                      </Link>
                    </div>
                  ) : (
                    <CommonHeader
                      showCloseButton={false}
                      showInstagram={false}
                      colorMode={`white`}
                      title={`SKWAT<br>MARKET`}
                    />
                  )}
                </div>

                {/* main */}
                <section>
                  {/* Slick Slider */}
                  <div className={css.wrap + ` ` + slickBoxIsModal}>
                    <div className={css.content + ` vs-modal-content`}>
                      <div className={css.contentSlider}>
                        <Slider {...settings}>{images}</Slider>
                      </div>

                      <div className={css.contentProductInfo}>
                        <ProductInfo
                          title={theProduct.title}
                          variants={this.state.product.variants}
                          shippingHTML={this.props.pageContext.shippingHTML}
                          currentVatiant={this.state.variantIndex}
                          onVariantChanged={this._onVariantUpdate}
                          onAddVariantToCart={this._addItemToCart}
                        >
                          {theProduct.descriptionHtml}
                        </ProductInfo>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </CommonCartLayout>
          )
        }}
      </ModalRoutingContext.Consumer>
    )
  }
}
