import Client from "shopify-buy"

/**
 * シングルトンの Shopify とやり取りするようのクライアントです。
 */
class ShopifySingletonClient {
  constructor() {
    try {
      this._shopifyCheckOut = null
      this._shopifyClient = Client.buildClient({
        storefrontAccessToken: "9926c38e749ea15872b561ae21c4a59e",
        domain: "cart.skwat.site",
      })
      this._doUpdateCheckoutState = this._doUpdateCheckoutState.bind(this)

      if (this._shopifyClient.checkout) {
        // console.log(`checkout を作成します`)
        this._shopifyClient.checkout.create().then(this._doUpdateCheckoutState)
      }
    } catch (e) {}
  }

  _doUpdateCheckoutState(res) {
    this._shopifyCheckOut = res
    // console.log(`checkout 情報を更新しました`, this._shopifyCheckOut)
  }

  getCheckout() {
    return this._shopifyCheckOut
  }
  setCheckout(res) {
    this._shopifyCheckOut = res
    return this._shopifyCheckOut
  }

  getClient() {
    return this._shopifyClient
  }
}

export default new ShopifySingletonClient()
